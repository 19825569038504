import { getColor, IIconProps } from './utils';

export const CloudIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.5599 12.28C23.7443 10.4077 22.3371 8.85466 20.5542 7.85887C18.7712 6.86308 16.7109 6.47955 14.689 6.76709C12.6672 7.05463 10.7955 7.99736 9.36081 9.45074C7.92617 10.9041 7.0078 12.7879 6.74652 14.8133C5.4753 15.1178 4.36015 15.8784 3.61284 16.9509C2.86553 18.0234 2.5381 19.333 2.69274 20.6309C2.84738 21.9289 3.47331 23.1249 4.45168 23.9918C5.43004 24.8587 6.69269 25.3361 7.99985 25.3333H22.6665C24.2657 25.3289 25.8099 24.7498 27.0177 23.7017C28.2255 22.6536 29.0162 21.2062 29.2458 19.6236C29.4753 18.0411 29.1284 16.4287 28.2682 15.0806C27.408 13.7325 26.0918 12.7386 24.5599 12.28ZM22.6665 22.6667H7.99985C7.29261 22.6667 6.61433 22.3857 6.11424 21.8856C5.61414 21.3855 5.33319 20.7072 5.33319 20C5.33319 19.2928 5.61414 18.6145 6.11424 18.1144C6.61433 17.6143 7.29261 17.3333 7.99985 17.3333C8.35348 17.3333 8.69261 17.1929 8.94266 16.9428C9.19271 16.6928 9.33319 16.3536 9.33319 16C9.33659 14.423 9.8989 12.8983 10.9202 11.6968C11.9415 10.4952 13.3557 9.69459 14.9115 9.43716C16.4673 9.17972 18.064 9.48214 19.4179 10.2907C20.7719 11.0992 21.7953 12.3615 22.3065 13.8533C22.3847 14.08 22.5226 14.2813 22.7056 14.4362C22.8887 14.591 23.1101 14.6937 23.3465 14.7333C24.3238 14.9137 25.199 15.4514 25.8016 16.2417C26.4042 17.032 26.691 18.0183 26.6061 19.0085C26.5212 19.9987 26.0708 20.9219 25.3425 21.5981C24.6142 22.2744 23.6603 22.6553 22.6665 22.6667Z" />
  </svg>
);
