import { getColor, IIconProps } from './utils';

export const FiltersWithCounterIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 29 26"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3535 10.75H4C3.58579 10.75 3.25 10.4142 3.25 10C3.25 9.58579 3.58579 9.25 4 9.25H13.3535C13.68 8.09575 14.7412 7.25 16 7.25C17.2588 7.25 18.32 8.09575 18.6465 9.25H20C20.4142 9.25 20.75 9.58579 20.75 10C20.75 10.4142 20.4142 10.75 20 10.75H18.6465C18.32 11.9043 17.2588 12.75 16 12.75C14.7412 12.75 13.68 11.9043 13.3535 10.75ZM14.75 10C14.75 9.30964 15.3096 8.75 16 8.75C16.6904 8.75 17.25 9.30964 17.25 10C17.25 10.6904 16.6904 11.25 16 11.25C15.3096 11.25 14.75 10.6904 14.75 10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6465 18.75H20C20.4142 18.75 20.75 18.4142 20.75 18C20.75 17.5858 20.4142 17.25 20 17.25H10.6465C10.32 16.0957 9.25878 15.25 8 15.25C6.74122 15.25 5.67998 16.0957 5.35352 17.25H4C3.58579 17.25 3.25 17.5858 3.25 18C3.25 18.4142 3.58579 18.75 4 18.75H5.35352C5.67998 19.9043 6.74122 20.75 8 20.75C9.25878 20.75 10.32 19.9043 10.6465 18.75ZM6.75 18C6.75 17.3096 7.30964 16.75 8 16.75C8.69036 16.75 9.25 17.3096 9.25 18C9.25 18.6904 8.69036 19.25 8 19.25C7.30964 19.25 6.75 18.6904 6.75 18Z"
    />
    <rect x="13" width="16" height="16" rx="8" fill="#FF275E" />
    <path
      d="M23.7983 12H18.3023V10.968L21.0383 8.448C21.4303 8.088 21.7583 7.708 22.0223 7.308C22.2863 6.9 22.4183 6.492 22.4183 6.084V5.94C22.4183 5.476 22.2903 5.1 22.0343 4.812C21.7783 4.524 21.3903 4.38 20.8703 4.38C20.6143 4.38 20.3903 4.416 20.1983 4.488C20.0063 4.56 19.8383 4.66 19.6943 4.788C19.5583 4.916 19.4423 5.068 19.3463 5.244C19.2583 5.412 19.1863 5.596 19.1303 5.796L18.2303 5.448C18.3103 5.2 18.4183 4.96 18.5543 4.728C18.6983 4.488 18.8743 4.276 19.0823 4.092C19.2983 3.908 19.5543 3.76 19.8503 3.648C20.1543 3.536 20.5103 3.48 20.9183 3.48C21.3263 3.48 21.6903 3.54 22.0103 3.66C22.3303 3.78 22.5983 3.948 22.8143 4.164C23.0303 4.38 23.1943 4.636 23.3063 4.932C23.4263 5.228 23.4863 5.556 23.4863 5.916C23.4863 6.244 23.4383 6.548 23.3423 6.828C23.2543 7.108 23.1303 7.376 22.9703 7.632C22.8103 7.88 22.6143 8.124 22.3823 8.364C22.1583 8.604 21.9143 8.844 21.6503 9.084L19.3943 11.112H23.7983V12Z"
      fill="white"
    />
  </svg>
);
