import { getColor, IIconProps } from './utils';

export const SocialBehanceIcon = ({
  color,
  size = '80',
  ...props
}: IIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.1786 6.67626C10.6071 5.98561 10.8214 5.17985 10.8214 4.25899C10.8214 1.84172 8.89286 0 6.75 0H0.535714C0.214286 0 0 0.230216 0 0.57554V15.1942C0 15.5396 0.214286 15.7698 0.535714 15.7698H7.39286C9.96429 15.7698 12.1071 13.5827 12.1071 10.8201C12.1071 9.09352 11.3571 7.59713 10.1786 6.67626ZM2.67857 2.9928H6.75C7.5 2.9928 8.03571 3.68346 8.03571 4.3741C8.03571 5.17986 7.39286 5.7554 6.75 5.7554H2.67857V2.9928ZM7.5 12.8921H2.67857V8.74821H7.5C8.57143 8.74821 9.42857 9.66906 9.42857 10.8201C9.42857 11.9712 8.57143 12.8921 7.5 12.8921Z"
      fill={getColor(color)}
    />
    <path
      d="M24 9.78418C24 6.33094 21.5357 3.56835 18.4286 3.56835C15.3214 3.56835 12.8571 6.33094 12.8571 9.78418C12.8571 13.2374 15.3214 16 18.4286 16C20.25 16 21.9643 14.964 23.0357 13.2374C23.1429 13.0072 23.25 12.777 23.3571 12.5468C23.4643 12.4317 23.3571 12.2015 23.1429 12.2015H20.4643C20.3571 12.2015 20.3571 12.2014 20.25 12.3166C19.7143 12.8921 19.0714 13.1223 18.4286 13.1223C17.0357 13.1223 15.9643 12.0863 15.6429 10.5899H23.4643C23.7857 10.5899 24 10.3597 24 10.0144V9.78418ZM18.4286 6.56116C19.3929 6.56116 20.25 7.1367 20.7857 8.05756H16.0714C16.5 7.1367 17.4643 6.56116 18.4286 6.56116Z"
      fill={getColor(color)}
    />
    <path
      d="M20.6786 2.87771H15.75C15.5357 2.87771 15.3214 2.6475 15.3214 2.41728V1.1511C15.3214 0.92088 15.5357 0.690662 15.75 0.690662H20.6786C20.8929 0.690662 21.1071 0.92088 21.1071 1.1511V2.53239C21.1071 2.6475 21 2.87771 20.6786 2.87771Z"
      fill={getColor(color)}
    />
  </svg>
);
