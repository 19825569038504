import { getColor, IIconProps } from './utils';

export const DirectionToolIcon = ({
  color,
  size = '32',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.1065 6.66666L13.3332 5.77332L9.33317 5.43999V3.99999C9.33317 3.64637 9.19269 3.30723 8.94265 3.05718C8.6926 2.80713 8.35346 2.66666 7.99984 2.66666C7.64622 2.66666 7.30708 2.80713 7.05703 3.05718C6.80698 3.30723 6.6665 3.64637 6.6665 3.99999V28C6.6665 28.3536 6.80698 28.6928 7.05703 28.9428C7.30708 29.1929 7.64622 29.3333 7.99984 29.3333C8.35346 29.3333 8.6926 29.1929 8.94265 28.9428C9.19269 28.6928 9.33317 28.3536 9.33317 28V18.56L13.3332 18.2267L24.1065 17.3333C24.4413 17.3065 24.7537 17.1542 24.9811 16.907C25.2085 16.6598 25.3342 16.3359 25.3332 16V7.99999C25.3342 7.6641 25.2085 7.34017 24.9811 7.09298C24.7537 6.84578 24.4413 6.69353 24.1065 6.66666ZM11.9998 15.6667L9.33317 15.88V8.11999L11.9998 8.33332V15.6667ZM17.3332 15.2133L14.6665 15.44V8.55999L17.3332 8.78666V15.2133ZM22.6665 14.7733L19.9998 15V8.99999L22.6665 9.22666V14.7733Z" />
  </svg>
);
