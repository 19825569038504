import { getColor, IIconProps } from './utils';

export const UserIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.17791 8.50173C8.17791 4.35863 11.5366 0.999985 15.6796 0.999985C19.8227 0.999985 23.1814 4.35863 23.1814 8.50173C23.1814 12.6448 19.8227 16.0035 15.6796 16.0035C11.5366 16.0035 8.17791 12.6448 8.17791 8.50173ZM15.6796 3.64766C12.9988 3.64766 10.8256 5.8209 10.8256 8.50173C10.8256 11.1826 12.9988 13.3558 15.6796 13.3558C18.3605 13.3558 20.5337 11.1826 20.5337 8.50173C20.5337 5.8209 18.3605 3.64766 15.6796 3.64766Z" />
    <path d="M8.61918 21.2988C6.42578 21.2988 4.64767 23.0769 4.64767 25.2703V27.3678C4.64767 27.3997 4.67082 27.427 4.70234 27.4321C11.9724 28.619 19.3869 28.619 26.657 27.4321C26.6885 27.427 26.7116 27.3997 26.7116 27.3678V25.2703C26.7116 23.0769 24.9335 21.2988 22.7401 21.2988H22.1384C22.0919 21.2988 22.0457 21.3062 22.0015 21.3206L20.4737 21.8195C17.3586 22.8366 14.0007 22.8366 10.8856 21.8195L9.35783 21.3206C9.31361 21.3062 9.26738 21.2988 9.22086 21.2988H8.61918ZM2 25.2703C2 21.6147 4.96351 18.6511 8.61918 18.6511H9.22086C9.54651 18.6511 9.87011 18.7026 10.1797 18.8037L11.7074 19.3026C14.2885 20.1454 17.0708 20.1454 19.6519 19.3026L21.1796 18.8037C21.4892 18.7026 21.8128 18.6511 22.1384 18.6511H22.7401C26.3958 18.6511 29.3593 21.6147 29.3593 25.2703V27.3678C29.3593 28.6973 28.3957 29.8309 27.0836 30.0452C19.531 31.2783 11.8283 31.2783 4.27571 30.0452C2.96355 29.8309 2 28.6973 2 27.3678V25.2703Z" />
  </svg>
);
