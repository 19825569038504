import { getColor, IIconProps } from './utils';

export const PediatricsIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 7C7.71667 7 7.479 6.904 7.287 6.712C7.095 6.52 6.99934 6.28267 7 6C7 5.71667 7.096 5.479 7.288 5.287C7.48 5.095 7.71734 4.99934 8 5H11V3C11 2.71667 11.096 2.479 11.288 2.287C11.48 2.095 11.7173 1.99934 12 2C12.2833 2 12.521 2.096 12.713 2.288C12.905 2.48 13.0007 2.71734 13 3V5H16C16.2833 5 16.521 5.096 16.713 5.288C16.905 5.48 17.0007 5.71734 17 6C17 6.28334 16.904 6.521 16.712 6.713C16.52 6.905 16.2827 7.00067 16 7H8ZM9 22C8.45 22 7.979 21.804 7.587 21.412C7.195 21.02 6.99934 20.5493 7 20V11C7 10.1667 7.29167 9.45834 7.875 8.875C8.45834 8.29167 9.16667 8 10 8H14C14.8333 8 15.5417 8.29167 16.125 8.875C16.7083 9.45834 17 10.1667 17 11V20C17 20.55 16.804 21.021 16.412 21.413C16.02 21.805 15.5493 22.0007 15 22H9ZM9 20H15V11C15 10.7167 14.904 10.479 14.712 10.287C14.52 10.095 14.2827 9.99934 14 10H10C9.71667 10 9.479 10.096 9.287 10.288C9.095 10.48 8.99934 10.7173 9 11V12H11C11.2833 12 11.521 12.096 11.713 12.288C11.905 12.48 12.0007 12.7173 12 13C12 13.2833 11.904 13.521 11.712 13.713C11.52 13.905 11.2827 14.0007 11 14H9V16H11C11.2833 16 11.521 16.096 11.713 16.288C11.905 16.48 12.0007 16.7173 12 17C12 17.2833 11.904 17.521 11.712 17.713C11.52 17.905 11.2827 18.0007 11 18H9V20Z" />
  </svg>
);
