import { getColor, IIconProps } from './utils';

export const YoutubeIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill={getColor(color)} />
    <path
      d="M26.221 10.3682C25.9756 9.4365 25.2524 8.702 24.3351 8.45283C22.6711 8 16.0002 8 16.0002 8C16.0002 8 9.32923 8 7.66523 8.45283C6.7479 8.702 6.0247 9.4365 5.77936 10.3682C5.3335 12.0582 5.3335 16.6667 5.3335 16.6667C5.3335 16.6667 5.3335 21.2752 5.77936 22.9652C6.0247 23.8968 6.7479 24.6313 7.66523 24.8805C9.32923 25.3333 16.0002 25.3333 16.0002 25.3333C16.0002 25.3333 22.6711 25.3333 24.3351 24.8805C25.2535 24.6313 25.9756 23.8968 26.221 22.9652C26.6668 21.2752 26.6668 16.6667 26.6668 16.6667C26.6668 16.6667 26.6668 12.0582 26.221 10.3682ZM13.8668 20.4193V12.914L20.2668 16.6667L13.8668 20.4193Z"
      fill="white"
    />
  </svg>
);
