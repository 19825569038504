import { getColor, IIconProps } from './utils';

export const ForwardIcon = ({ color, size = '24', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.46014 16.8191C2.23856 16.5893 2.1867 16.2445 2.33091 15.9597L3.03241 14.5743C4.69268 11.2955 8.05563 9.22883 11.7308 9.22883H12.0757C12.1009 8.68245 12.1334 8.13633 12.1731 7.5906L12.241 6.65996C12.2961 5.90314 13.14 5.48007 13.7795 5.88866C15.8771 7.22902 17.7007 8.95611 19.153 10.9779L19.6091 11.6128C19.797 11.8743 19.797 12.2265 19.6091 12.4879L19.153 13.1229C17.7007 15.1446 15.8771 16.8717 13.7795 18.2121C13.14 18.6207 12.2961 18.1976 12.241 17.4408L12.1731 16.5101C12.1256 15.8584 12.0886 15.2061 12.0619 14.5535C9.91207 14.4914 7.76486 14.9254 5.7915 15.8361L3.31431 16.9795C3.02447 17.1132 2.68173 17.0489 2.46014 16.8191ZM4.67839 14.6978L5.16291 14.4742C7.57405 13.3614 10.2196 12.8916 12.8447 13.0932C13.227 13.1226 13.5256 13.4356 13.5369 13.8188C13.5624 14.6802 13.6065 15.5411 13.6692 16.4011L13.6746 16.4755C15.3181 15.3139 16.7583 13.8855 17.9348 12.2477L18.0766 12.0504L17.9348 11.853C16.7583 10.2152 15.3181 8.78686 13.6746 7.6252L13.6692 7.69965C13.6132 8.46726 13.5721 9.23568 13.5458 10.0045C13.532 10.4085 13.2005 10.7288 12.7962 10.7288L11.7308 10.7288C8.83352 10.7288 6.16555 12.2467 4.67839 14.6978Z" />
  </svg>
);
