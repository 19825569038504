import { getColor, IIconProps } from './utils';

export const ClockIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.0001 6.80395C15.9051 7.32646 16.6579 8.07628 17.184 8.97915C17.7102 9.88203 17.9914 10.9067 17.9998 11.9516C18.0082 12.9966 17.7436 14.0256 17.2322 14.9369C16.7207 15.8481 15.9801 16.61 15.0837 17.1471C14.1872 17.6841 13.1661 17.9778 12.1213 17.9989C11.0766 18.0201 10.0444 17.768 9.12697 17.2676C8.20956 16.7673 7.43874 16.036 6.89081 15.1462C6.34288 14.2563 6.03685 13.2388 6.003 12.1944L6 12L6.003 11.8056C6.0366 10.7694 6.33813 9.75956 6.87819 8.87457C7.41826 7.98957 8.17842 7.25962 9.08457 6.75587C9.99073 6.25213 11.0119 5.99177 12.0487 6.0002C13.0854 6.00862 14.1023 6.28554 15.0001 6.80395ZM12.0001 8.39996C11.8531 8.39998 11.7113 8.45394 11.6014 8.5516C11.4916 8.64925 11.4215 8.78382 11.4043 8.92977L11.4001 8.99997V12L11.4055 12.0786C11.4191 12.1827 11.4599 12.2814 11.5237 12.3648L11.5759 12.4248L13.3759 14.2248L13.4323 14.274C13.5375 14.3557 13.6669 14.4 13.8001 14.4C13.9333 14.4 14.0627 14.3557 14.1679 14.274L14.2243 14.2242L14.2741 14.1678C14.3557 14.0626 14.4001 13.9332 14.4001 13.8C14.4001 13.6668 14.3557 13.5375 14.2741 13.4322L14.2243 13.3758L12.6001 11.751V8.99997L12.5959 8.92977C12.5787 8.78382 12.5085 8.64925 12.3987 8.5516C12.2889 8.45394 12.147 8.39998 12.0001 8.39996Z" />
  </svg>
);
