import { getColor, IIconProps } from './utils';

export const OphthalmologyIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 11.6366C10.82 11.6366 9.86006 12.5966 9.86006 13.7765C9.86006 14.9566 10.8201 15.9165 12 15.9165C13.18 15.9165 14.14 14.9565 14.14 13.7765C14.14 12.5966 13.1801 11.6366 12 11.6366ZM12 14.3015C11.7106 14.3015 11.4751 14.066 11.4751 13.7765C11.4751 13.4871 11.7106 13.2516 12 13.2516C12.2895 13.2516 12.5249 13.4871 12.5249 13.7765C12.5249 14.066 12.2895 14.3015 12 14.3015Z" />
    <path d="M23.7413 13.1841C23.4851 12.9468 17.4057 7.37275 12 7.37275C11.3812 7.37275 10.7535 7.44741 10.1268 7.57735C9.41487 6.11109 8.38699 4.58955 8.09592 4.16814C7.94521 3.94988 7.69681 3.81953 7.43152 3.81953C7.16622 3.81953 6.91782 3.9498 6.76711 4.16814C6.30869 4.83182 4.0219 8.2239 4.0219 9.83133C4.0219 9.98471 4.03305 10.1354 4.05277 10.2835C1.9023 11.676 0.384 13.0681 0.258747 13.1841C0.0937374 13.337 0 13.5517 0 13.7765C0 14.0014 0.0937374 14.2161 0.258747 14.3689C0.514909 14.6063 6.59434 20.1803 12 20.1803C17.4057 20.1803 23.4851 14.6063 23.7413 14.3689C23.9063 14.2161 24 14.0014 24 13.7765C24 13.5517 23.9063 13.337 23.7413 13.1841ZM7.43152 6.09485C8.38238 7.60499 9.2261 9.23707 9.2261 9.83133C9.2261 10.8209 8.42109 11.6259 7.43152 11.6259C6.44194 11.6259 5.63693 10.8209 5.63693 9.83133C5.63693 9.23707 6.48057 7.60499 7.43152 6.09485ZM2.04218 13.7765C2.62739 13.29 3.55063 12.5626 4.66505 11.8208C4.99079 12.2725 5.42376 12.6416 5.92695 12.8901C5.88477 13.1795 5.86271 13.4755 5.86271 13.7765C5.86271 14.9581 6.19919 16.0622 6.78012 16.9996C4.73996 15.9204 2.96857 14.5468 2.04218 13.7765ZM12 18.2988C9.50642 18.2988 7.47782 16.2702 7.47782 13.7766C7.47782 13.5947 7.48889 13.4154 7.5099 13.2391C9.35378 13.1972 10.8411 11.6852 10.8411 9.83141C10.8411 9.70438 10.8255 9.56515 10.7989 9.41808C11.1817 9.31238 11.584 9.25444 12 9.25444C14.4936 9.25444 16.5222 11.283 16.5222 13.7766C16.5222 16.2702 14.4936 18.2988 12 18.2988ZM17.2199 16.9996C17.8008 16.0622 18.1373 14.9581 18.1373 13.7765C18.1373 12.595 17.8008 11.491 17.2199 10.5535C19.26 11.6327 21.0314 13.0063 21.9577 13.7765C21.0314 14.5468 19.26 15.9204 17.2199 16.9996Z" />
  </svg>
);
