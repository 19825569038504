import { getColor, IIconProps } from './utils';

export const ZenIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill={getColor(color)} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8826 5.33334C11.4859 5.33376 8.43938 5.34839 6.87557 6.87543C5.34854 8.43883 5.33391 11.6283 5.33391 15.8818C10.0571 15.7393 12.217 15.621 13.9397 13.9389C15.6218 12.2163 15.7401 10.0566 15.8826 5.33334ZM6.8753 25.1238C5.34827 23.5604 5.33364 20.371 5.33364 16.1174C10.0569 16.26 12.2168 16.3782 13.9394 18.0603C15.6215 19.7829 15.7398 21.9427 15.8823 26.6655C11.4856 26.6655 8.43911 26.6513 6.8753 25.1238ZM16.1181 26.6655C16.2607 21.9427 16.379 19.7829 18.0611 18.0603C19.7837 16.3782 21.9436 16.26 26.6668 16.1174C26.6664 20.371 26.6518 23.5604 25.1248 25.1238C23.5609 26.6513 20.5149 26.6655 16.1181 26.6655ZM16.1181 5.33334C16.2607 10.0566 16.379 12.2163 18.0611 13.9389C19.7837 15.621 21.9436 15.7393 26.6668 15.8818C26.6664 11.6283 26.6518 8.43883 25.1248 6.87543C23.5609 5.34839 20.5149 5.33376 16.1181 5.33334Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.6668 16.1174L26.6667 16.0004L26.6668 15.8818C21.9436 15.7393 19.7837 15.621 18.0611 13.9389C16.379 12.2163 16.2607 10.0566 16.1181 5.33334L16.0001 5.3345H15.8822C15.7397 10.0577 15.6218 12.2167 13.9397 13.9389C12.217 15.6214 10.0567 15.74 5.3335 15.8829V16.0004L5.33364 16.1174C10.0569 16.26 12.2168 16.3782 13.9394 18.0603C15.6215 19.7829 15.7398 21.9427 15.8823 26.6655L16.0001 26.6667L16.1181 26.6655C16.2607 21.9427 16.379 19.7829 18.0611 18.0603C19.7837 16.3782 21.9436 16.26 26.6668 16.1174Z"
      fill="#014CFF"
    />
  </svg>
);
