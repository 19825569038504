import { getColor, IIconProps } from './utils';

export const LocationPinIcon = ({
  color,
  size = '16',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.0001 2.98669C10.9392 1.92582 9.50041 1.32983 8.00012 1.32983C6.49983 1.32983 5.06098 1.92582 4.00012 2.98669C2.93925 4.04755 2.34326 5.4864 2.34326 6.98669C2.34326 8.48698 2.93925 9.92582 4.00012 10.9867L7.51345 14.5067C7.57542 14.5692 7.64916 14.6188 7.7304 14.6526C7.81164 14.6865 7.89877 14.7039 7.98678 14.7039C8.07479 14.7039 8.16193 14.6865 8.24317 14.6526C8.32441 14.6188 8.39814 14.5692 8.46012 14.5067L12.0001 10.9534C13.0566 9.8969 13.65 8.46406 13.65 6.97002C13.65 5.47599 13.0566 4.04314 12.0001 2.98669ZM11.0468 10L8.00012 13.06L4.95345 10C4.35155 9.39757 3.94177 8.63019 3.77591 7.79488C3.61006 6.95958 3.69557 6.09385 4.02165 5.30714C4.34773 4.52043 4.89973 3.84805 5.60787 3.37501C6.31601 2.90196 7.14851 2.64948 8.00012 2.64948C8.85173 2.64948 9.68422 2.90196 10.3924 3.37501C11.1005 3.84805 11.6525 4.52043 11.9786 5.30714C12.3047 6.09385 12.3902 6.95958 12.2243 7.79488C12.0585 8.63019 11.6487 9.39757 11.0468 10ZM6.00012 4.94002C5.46193 5.47987 5.15971 6.21107 5.15971 6.97336C5.15971 7.73564 5.46193 8.46684 6.00012 9.00669C6.39996 9.40721 6.90917 9.68074 7.46388 9.79296C8.01859 9.90517 8.59408 9.85108 9.11817 9.63746C9.64225 9.42384 10.0916 9.06021 10.4098 8.59219C10.728 8.12417 10.9009 7.5726 10.9068 7.00669C10.9098 6.62882 10.837 6.25419 10.6927 5.90495C10.5484 5.5557 10.3356 5.23893 10.0668 4.97336C9.80256 4.70307 9.4875 4.48771 9.13973 4.33967C8.79196 4.19162 8.41834 4.11381 8.04038 4.11071C7.66242 4.10761 7.28758 4.17929 6.93743 4.32161C6.58728 4.46394 6.26873 4.67411 6.00012 4.94002ZM9.12678 8.06002C8.87415 8.31652 8.54026 8.47729 8.1822 8.51486C7.82415 8.55242 7.46416 8.46445 7.16379 8.26597C6.86342 8.0675 6.64131 7.77085 6.53545 7.42674C6.42959 7.08264 6.44655 6.71245 6.58342 6.37946C6.72029 6.04647 6.96858 5.77137 7.28584 5.60118C7.6031 5.43099 7.96962 5.37629 8.32274 5.44643C8.67586 5.51657 8.99366 5.70719 9.22179 5.98571C9.44992 6.26422 9.57423 6.61334 9.57345 6.97336C9.56375 7.38487 9.39111 7.77571 9.09345 8.06002H9.12678Z" />
  </svg>
);
