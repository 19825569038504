import { getColor, IIconProps } from './utils';

export const SearchIcon = ({ color, size = '24', ...props }: IIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <path
      d="M20.7099 19.29L16.9999 15.61C18.44 13.8144 19.1374 11.5353 18.9487 9.2413C18.76 6.9473 17.6996 4.81278 15.9854 3.27664C14.2713 1.7405 12.0337 0.919506 9.73283 0.982466C7.43194 1.04543 5.24263 1.98756 3.61505 3.61514C1.98747 5.24272 1.04534 7.43203 0.982375 9.73292C0.919414 12.0338 1.74041 14.2714 3.27655 15.9855C4.81269 17.6997 6.94721 18.7601 9.2412 18.9488C11.5352 19.1375 13.8143 18.4401 15.6099 17L19.2899 20.68C19.3829 20.7737 19.4935 20.8481 19.6153 20.8989C19.7372 20.9497 19.8679 20.9758 19.9999 20.9758C20.1319 20.9758 20.2626 20.9497 20.3845 20.8989C20.5063 20.8481 20.6169 20.7737 20.7099 20.68C20.8901 20.4935 20.9909 20.2443 20.9909 19.985C20.9909 19.7257 20.8901 19.4765 20.7099 19.29ZM9.9999 17C8.61544 17 7.26206 16.5895 6.11091 15.8203C4.95977 15.0511 4.06256 13.9579 3.53275 12.6788C3.00293 11.3997 2.86431 9.99223 3.13441 8.63436C3.4045 7.2765 4.07119 6.02922 5.05016 5.05025C6.02912 4.07128 7.27641 3.4046 8.63427 3.1345C9.99214 2.8644 11.3996 3.00303 12.6787 3.53284C13.9578 4.06265 15.051 4.95986 15.8202 6.111C16.5894 7.26215 16.9999 8.61553 16.9999 10C16.9999 11.8565 16.2624 13.637 14.9497 14.9497C13.6369 16.2625 11.8564 17 9.9999 17Z"
      fill={getColor(color)}
    />
  </svg>
);
