import { getColor, IIconProps } from './utils';

export const AllIcon = ({ color, size = '24', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 3C4.34315 3 3 4.34314 3 6V8C3 9.65685 4.34315 11 6 11H8C9.65685 11 11 9.65685 11 8V6C11 4.34315 9.65685 3 8 3H6ZM5 6C5 5.44772 5.44771 5 6 5H8C8.55228 5 9 5.44772 9 6V8C9 8.55228 8.55228 9 8 9H6C5.44772 9 5 8.55228 5 8V6Z" />
    <path d="M6 13C4.34315 13 3 14.3431 3 16V18C3 19.6569 4.34315 21 6 21H8C9.65685 21 11 19.6569 11 18V16C11 14.3431 9.65685 13 8 13H6ZM5 16C5 15.4477 5.44771 15 6 15H8C8.55228 15 9 15.4477 9 16V18C9 18.5523 8.55228 19 8 19H6C5.44772 19 5 18.5523 5 18V16Z" />
    <path d="M13 16C13 14.3431 14.3431 13 16 13H18C19.6569 13 21 14.3431 21 16V18C21 19.6569 19.6569 21 18 21H16C14.3431 21 13 19.6569 13 18V16ZM16 15C15.4477 15 15 15.4477 15 16V18C15 18.5523 15.4477 19 16 19H18C18.5523 19 19 18.5523 19 18V16C19 15.4477 18.5523 15 18 15H16Z" />
    <path d="M16 3C14.3431 3 13 4.34314 13 6V8C13 9.65685 14.3431 11 16 11H18C19.6569 11 21 9.65685 21 8V6C21 4.34315 19.6569 3 18 3H16ZM15 6C15 5.44772 15.4477 5 16 5H18C18.5523 5 19 5.44772 19 6V8C19 8.55228 18.5523 9 18 9H16C15.4477 9 15 8.55228 15 8V6Z" />
  </svg>
);
