import { getColor, IIconProps } from './utils';

export const RightArrowIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.6652 11.2279L11.7791 5.30983C11.6825 5.21166 11.5674 5.13373 11.4407 5.08056C11.314 5.02738 11.1781 5 11.0408 5C10.9035 5 10.7676 5.02738 10.6408 5.08056C10.5141 5.13373 10.3991 5.21166 10.3024 5.30983C10.1087 5.50609 10 5.77156 10 6.04829C10 6.32501 10.1087 6.59049 10.3024 6.78674L15.4501 12.024L10.3024 17.2089C10.1087 17.4051 10 17.6706 10 17.9473C10 18.224 10.1087 18.4895 10.3024 18.6858C10.3987 18.7847 10.5136 18.8635 10.6403 18.9174C10.7671 18.9714 10.9032 18.9994 11.0408 19C11.1784 18.9994 11.3145 18.9714 11.4412 18.9174C11.568 18.8635 11.6828 18.7847 11.7791 18.6858L17.6652 12.7677C17.7708 12.6696 17.855 12.5505 17.9126 12.418C17.9703 12.2855 18 12.1425 18 11.9978C18 11.8531 17.9703 11.7101 17.9126 11.5776C17.855 11.4451 17.7708 11.326 17.6652 11.2279Z" />
  </svg>
);
