import { Spinner } from 'shared/preloaders/circle-with-dots/circle-with-dots';
import { IIconProps, getColor } from './utils';

export const StatusIcon = ({ color, size, status, ...props }: IIconProps) => {
  if (status === 'success') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 140 140"
        fill="none"
        {...props}
      >
        <path
          d="M140 70C140 108.66 108.66 140 70 140C31.3401 140 0 108.66 0 70C0 31.3401 31.3401 0 70 0C108.66 0 140 31.3401 140 70ZM6.125 70C6.125 105.277 34.7228 133.875 70 133.875C105.277 133.875 133.875 105.277 133.875 70C133.875 34.7228 105.277 6.125 70 6.125C34.7228 6.125 6.125 34.7228 6.125 70Z"
          fill="#ECECEC"
        />
        <circle cx="70" cy="70" r="64" fill={getColor(color)} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106 42.9663C107.653 44.621 107.227 46.6251 104.943 49.0037C102.658 51.3823 60.2601 96.1663 60.2601 96.1663C59.628 97.1514 58.5963 97.8089 57.4379 97.9645C56.2795 98.1202 55.1113 97.7584 54.2426 96.975L34.3191 79.0075C32.6834 77.5323 32.5507 75.0077 34.0229 73.3686C35.4951 71.7295 38.0146 71.5967 39.6504 73.0718L56.0905 87.898L99.5239 43.8829C101.516 41.8895 104.348 41.3116 106 42.9663Z"
          fill="#34C759"
        />
      </svg>
    );
  }

  if (status === 'fail') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 140 140"
        fill="none"
        {...props}
      >
        <path
          d="M140 70C140 108.66 108.66 140 70 140C31.3401 140 0 108.66 0 70C0 31.3401 31.3401 0 70 0C108.66 0 140 31.3401 140 70ZM6.125 70C6.125 105.277 34.7228 133.875 70 133.875C105.277 133.875 133.875 105.277 133.875 70C133.875 34.7228 105.277 6.125 70 6.125C34.7228 6.125 6.125 34.7228 6.125 70Z"
          fill="#ECECEC"
        />
        <circle cx="70" cy="70" r="64" fill={getColor(color)} />
        <path
          d="M74.9314 69.997L96.9655 47.9956C97.624 47.337 97.994 46.4436 97.994 45.5122C97.994 44.5807 97.624 43.6873 96.9655 43.0287C96.3069 42.37 95.4136 42 94.4823 42C93.5509 42 92.6576 42.37 91.9991 43.0287L70 65.0651L48.0009 43.0287C47.3424 42.37 46.4491 42 45.5177 42C44.5864 42 43.6931 42.37 43.0345 43.0287C42.376 43.6873 42.006 44.5807 42.006 45.5122C42.006 46.4436 42.376 47.337 43.0345 47.9956L65.0686 69.997L43.0345 91.9984C42.7067 92.3236 42.4465 92.7104 42.269 93.1367C42.0914 93.5629 42 94.0201 42 94.4819C42 94.9436 42.0914 95.4008 42.269 95.8271C42.4465 96.2533 42.7067 96.6402 43.0345 96.9653C43.3597 97.2932 43.7465 97.5534 44.1727 97.731C44.5989 97.9086 45.056 98 45.5177 98C45.9795 98 46.4366 97.9086 46.8628 97.731C47.289 97.5534 47.6758 97.2932 48.0009 96.9653L70 74.929L91.9991 96.9653C92.3242 97.2932 92.711 97.5534 93.1372 97.731C93.5634 97.9086 94.0205 98 94.4823 98C94.944 98 95.4011 97.9086 95.8273 97.731C96.2535 97.5534 96.6403 97.2932 96.9655 96.9653C97.2933 96.6402 97.5535 96.2533 97.731 95.8271C97.9086 95.4008 98 94.9436 98 94.4819C98 94.0201 97.9086 93.5629 97.731 93.1367C97.5535 92.7104 97.2933 92.3236 96.9655 91.9984L74.9314 69.997Z"
          fill="#FF3B30"
        />
      </svg>
    );
  }

  return <Spinner size="big" color="gray" />;
};
