import { getColor, IIconProps } from './utils';

export const GastroenterologyIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3 16C3 15.1667 3.29167 14.4583 3.875 13.875C4.45834 13.2917 5.16667 13 6 13H8C8.83334 13 9.54167 12.7083 10.125 12.125C10.7083 11.5417 11 10.8333 11 10C11 9.71667 10.904 9.479 10.712 9.287C10.52 9.095 10.2827 8.99934 10 9C9.45 9 8.979 8.804 8.587 8.412C8.195 8.02 7.99934 7.54934 8 7V3C8 2.71667 8.096 2.479 8.288 2.287C8.48 2.095 8.71734 1.99934 9 2C9.28334 2 9.521 2.096 9.713 2.288C9.905 2.48 10.0007 2.71734 10 3V7C10.8333 7 11.5417 7.29167 12.125 7.875C12.7083 8.45834 13 9.16667 13 10C13 11.3833 12.5123 12.5627 11.537 13.538C10.5617 14.5133 9.38267 15.0007 8 15H6C5.71667 15 5.479 15.096 5.287 15.288C5.095 15.48 4.99934 15.7173 5 16V21C5 21.2833 4.904 21.521 4.712 21.713C4.52 21.905 4.28267 22.0007 4 22C3.71667 22 3.479 21.904 3.287 21.712C3.095 21.52 2.99934 21.2827 3 21V16ZM7 20C7 19.1667 7.29167 18.4583 7.875 17.875C8.45834 17.2917 9.16667 17 10 17H14C15.3833 17 16.5627 16.5123 17.538 15.537C18.5133 14.5617 19.0007 13.3827 19 12V11C19 9.61667 18.5123 8.43734 17.537 7.462C16.5617 6.48667 15.3827 5.99934 14 6C13.45 6 12.979 5.804 12.587 5.412C12.195 5.02 11.9993 4.54934 12 4V3C12 2.71667 12.096 2.479 12.288 2.287C12.48 2.095 12.7173 1.99934 13 2C13.2833 2 13.521 2.096 13.713 2.288C13.905 2.48 14.0007 2.71734 14 3V4C15.95 4 17.6043 4.67934 18.963 6.038C20.3217 7.39667 21.0007 9.05067 21 11V12C21 13.95 20.3207 15.6043 18.962 16.963C17.6033 18.3217 15.9493 19.0007 14 19H10C9.71667 19 9.479 19.096 9.287 19.288C9.095 19.48 8.99934 19.7173 9 20V21C9 21.2833 8.904 21.521 8.712 21.713C8.52 21.905 8.28267 22.0007 8 22C7.71667 22 7.479 21.904 7.287 21.712C7.095 21.52 6.99934 21.2827 7 21V20ZM5 16C5 15.7167 5.096 15.479 5.288 15.287C5.48 15.095 5.71734 14.9993 6 15H8C9.38334 15 10.5627 14.5123 11.538 13.537C12.5133 12.5617 13.0007 11.3827 13 10C13 11.3833 12.5123 12.5627 11.537 13.538C10.5617 14.5133 9.38267 15.0007 8 15H6C5.71667 15 5.479 15.096 5.287 15.288C5.095 15.48 4.99934 15.7173 5 16Z" />
  </svg>
);
