import { getColor, IIconProps } from './utils';

export const PodcastIcon = ({ color, size = '80', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M41.25 7.5C25.3975 7.5 12.5 20.3975 12.5 36.25C12.5 47.8725 19.4475 57.885 29.395 62.4125C29.118 60.4295 28.9195 58.4362 28.8 56.4375C22.0325 52.2425 17.5 44.775 17.5 36.25C17.5 23.155 28.155 12.5 41.25 12.5C54.345 12.5 65 23.155 65 36.25C65 44.7775 60.4675 52.245 53.7 56.435C53.5804 58.4337 53.3819 60.4269 53.105 62.41C63.055 57.8875 70 47.875 70 36.25C70 20.3975 57.1025 7.5 41.25 7.5ZM41.25 17.5C30.91 17.5 22.5 25.91 22.5 36.25C22.5009 39.048 23.1291 41.8102 24.3383 44.3334C25.5476 46.8566 27.3071 49.0765 29.4875 50.83C30.0833 49.2394 31.0653 47.8219 32.345 46.705C30.8276 45.4179 29.6083 43.8161 28.7717 42.0108C27.9351 40.2055 27.5011 38.2398 27.5 36.25C27.5 28.67 33.67 22.5 41.25 22.5C48.83 22.5 55 28.67 55 36.25C54.9989 38.2398 54.5649 40.2055 53.7283 42.0108C52.8917 43.8161 51.6724 45.4179 50.155 46.705C51.4365 47.8202 52.4188 49.2383 53.0125 50.83C55.1929 49.0765 56.9524 46.8566 58.1617 44.3334C59.3709 41.8102 59.9991 39.048 60 36.25C60 25.91 51.59 17.5 41.25 17.5ZM41.25 27.5C36.425 27.5 32.5 31.425 32.5 36.25C32.5 41.075 36.425 45 41.25 45C46.075 45 50 41.075 50 36.25C50 31.425 46.075 27.5 41.25 27.5ZM41.25 32.5C43.3175 32.5 45 34.1825 45 36.25C45 38.3175 43.3175 40 41.25 40C39.1825 40 37.5 38.3175 37.5 36.25C37.5 34.1825 39.1825 32.5 41.25 32.5ZM41.25 47.5C33.3525 47.5 32.5 52.69 32.5 54.9225C32.5 59.0325 33.8425 67.2975 34.575 70.435C34.9 71.81 36.195 75 41.25 75C46.305 75 47.6 71.81 47.925 70.435C48.6575 67.3025 50 59.0325 50 54.9225C50 52.69 49.1475 47.5 41.25 47.5ZM41.25 52.5C45 52.5 45 53.9 45 54.9225C45 58.26 43.825 65.9975 43.0575 69.2925C42.995 69.555 42.89 70 41.25 70C39.61 70 39.505 69.5575 39.4425 69.2975C38.6775 66.0125 37.5 58.2625 37.5 54.9225C37.5 53.8975 37.5 52.5 41.25 52.5Z" />
  </svg>
);
