import { getColor, IIconProps } from './utils';

export const PlusIcon = ({ color, size = '24', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.1356 1.09076C6.08738 1.09076 6.04114 1.10991 6.00705 1.14401C5.97295 1.1781 5.9538 1.22434 5.9538 1.27255V4.90988C5.9538 5.05452 5.89634 5.19324 5.79406 5.29552C5.69178 5.3978 5.55307 5.45526 5.40842 5.45526H1.77255C1.72434 5.45526 1.6781 5.47441 1.64401 5.5085C1.60991 5.54259 1.59076 5.58883 1.59076 5.63705V10.3637C1.59076 10.464 1.6722 10.5455 1.77255 10.5455H5.40842C5.55307 10.5455 5.69178 10.6029 5.79406 10.7052C5.89634 10.8075 5.9538 10.9462 5.9538 11.0909V14.7274C5.9538 14.8278 6.03525 14.9092 6.1356 14.9092H10.8622C10.9104 14.9092 10.9567 14.8901 10.9908 14.856C11.0249 14.8219 11.044 14.7757 11.044 14.7274V11.0909C11.044 10.9462 11.1015 10.8075 11.2038 10.7052C11.306 10.6029 11.4448 10.5455 11.5894 10.5455H15.2253C15.2735 10.5455 15.3197 10.5263 15.3538 10.4922C15.3879 10.4581 15.4071 10.4119 15.4071 10.3637V5.63705C15.4071 5.58883 15.3879 5.54259 15.3538 5.5085C15.3197 5.47441 15.2735 5.45526 15.2253 5.45526H11.5894C11.4448 5.45526 11.306 5.3978 11.2038 5.29552C11.1015 5.19324 11.044 5.05452 11.044 4.90988V1.27328C11.044 1.22507 11.0249 1.17883 10.9908 1.14473C10.9567 1.11064 10.9104 1.09149 10.8622 1.09149L6.1356 1.09076ZM4.86304 1.27255C4.86304 0.570104 5.43315 0 6.1356 0H10.8622C11.5647 0 12.1348 0.570104 12.1348 1.27255V4.3645H15.2253C15.9277 4.3645 16.4978 4.93387 16.4978 5.63705V10.3637C16.4978 10.7012 16.3637 11.0249 16.1251 11.2635C15.8864 11.5022 15.5628 11.6362 15.2253 11.6362H12.1348V14.7274C12.1348 15.0649 12.0007 15.3886 11.7621 15.6273C11.5234 15.8659 11.1997 16 10.8622 16H6.1356C5.79809 16 5.47441 15.8659 5.23576 15.6273C4.99711 15.3886 4.86304 15.0649 4.86304 14.7274V11.6362H1.77255C1.43505 11.6362 1.11137 11.5022 0.872722 11.2635C0.634072 11.0249 0.5 10.7012 0.5 10.3637V5.63705C0.5 4.9346 1.0701 4.3645 1.77255 4.3645H4.86304V1.27255Z" />
    <path d="M6.1356 1.09076C6.08738 1.09076 6.04114 1.10991 6.00705 1.14401C5.97295 1.1781 5.9538 1.22434 5.9538 1.27255V4.90988C5.9538 5.05452 5.89634 5.19324 5.79406 5.29552C5.69178 5.3978 5.55307 5.45526 5.40842 5.45526H1.77255C1.72434 5.45526 1.6781 5.47441 1.64401 5.5085C1.60991 5.54259 1.59076 5.58883 1.59076 5.63705V10.3637C1.59076 10.464 1.6722 10.5455 1.77255 10.5455H5.40842C5.55307 10.5455 5.69178 10.6029 5.79406 10.7052C5.89634 10.8075 5.9538 10.9462 5.9538 11.0909V14.7274C5.9538 14.8278 6.03525 14.9092 6.1356 14.9092H10.8622C10.9104 14.9092 10.9567 14.8901 10.9908 14.856C11.0249 14.8219 11.044 14.7757 11.044 14.7274V11.0909C11.044 10.9462 11.1015 10.8075 11.2038 10.7052C11.306 10.6029 11.4448 10.5455 11.5894 10.5455H15.2253C15.2735 10.5455 15.3197 10.5263 15.3538 10.4922C15.3879 10.4581 15.4071 10.4119 15.4071 10.3637V5.63705C15.4071 5.58883 15.3879 5.54259 15.3538 5.5085C15.3197 5.47441 15.2735 5.45526 15.2253 5.45526H11.5894C11.4448 5.45526 11.306 5.3978 11.2038 5.29552C11.1015 5.19324 11.044 5.05452 11.044 4.90988V1.27328C11.044 1.22507 11.0249 1.17883 10.9908 1.14473C10.9567 1.11064 10.9104 1.09149 10.8622 1.09149L6.1356 1.09076Z" />
  </svg>
);
