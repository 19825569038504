import { getColor, IIconProps } from './utils';

export const SocialGithubIcon = ({
  color,
  size = '80',
  ...props
}: IIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96942 0.271484C4.46483 0.271484 0 4.73632 0 10.2409C0 14.6446 2.87462 18.3755 6.78899 19.721C7.27829 19.7822 7.46177 19.4764 7.46177 19.2317C7.46177 18.9871 7.46177 18.3755 7.46177 17.5192C4.70948 18.1308 4.09786 16.1736 4.09786 16.1736C3.66972 15.0115 2.99694 14.7057 2.99694 14.7057C2.07951 14.0941 3.0581 14.0941 3.0581 14.0941C4.0367 14.1553 4.58716 15.1339 4.58716 15.1339C5.50459 16.6629 6.91131 16.2348 7.46177 15.9901C7.52294 15.3174 7.82875 14.8892 8.07339 14.6446C5.87156 14.3999 3.5474 13.5437 3.5474 9.69044C3.5474 8.58953 3.91437 7.73326 4.58716 6.99931C4.52599 6.81583 4.15902 5.77607 4.70948 4.43051C4.70948 4.43051 5.56575 4.18586 7.46177 5.47026C8.25688 5.22561 9.11315 5.16445 9.96942 5.16445C10.8257 5.16445 11.682 5.28678 12.4771 5.47026C14.3731 4.18586 15.2294 4.43051 15.2294 4.43051C15.7798 5.77607 15.4128 6.81583 15.3517 7.06048C15.9633 7.73326 16.3914 8.65069 16.3914 9.75161C16.3914 13.6048 14.0673 14.3999 11.8654 14.6446C12.2324 14.9504 12.5382 15.562 12.5382 16.4794C12.5382 17.825 12.5382 18.8648 12.5382 19.2317C12.5382 19.4764 12.7217 19.7822 13.211 19.721C17.1865 18.3755 20 14.6446 20 10.2409C19.9388 4.73632 15.474 0.271484 9.96942 0.271484Z"
      fill={getColor(color)}
    />
  </svg>
);
