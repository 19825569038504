import { getColor, IIconProps } from './utils';

export const VkIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill={getColor(color)} />
    <path
      d="M17.0326 23.6979C17.5739 23.6979 17.7952 23.3199 17.789 22.8459C17.7614 21.0608 18.4236 20.0998 19.6191 21.3522C20.9417 22.7379 21.2155 23.6979 22.8217 23.6979H25.666C26.3842 23.6979 26.6668 23.4558 26.6668 23.0759C26.6668 22.2723 25.4038 20.854 24.3336 19.8129C22.835 18.3556 22.7648 18.3212 24.0554 16.5668C25.6562 14.3887 27.7503 11.5979 25.898 11.5979H22.3595C21.6733 11.5979 21.6235 12.0029 21.3791 12.6064C20.4946 14.7919 18.8138 17.6228 18.1756 17.1897C17.5081 16.7381 17.8139 14.9493 17.8645 12.2907C17.8779 11.5885 17.8743 11.1071 16.8503 10.8576C16.2913 10.7225 15.7473 10.6667 15.2424 10.6667C13.2221 10.6667 11.8283 11.5541 12.6203 11.7087C14.0167 11.9815 13.8825 15.1467 13.5572 16.5137C12.9901 18.8939 10.8586 14.6289 9.97064 12.5049C9.75642 11.9946 9.69065 11.5979 8.92624 11.5979H6.03302C5.59571 11.5979 5.3335 11.7469 5.3335 12.0784C5.3335 12.6389 7.9645 18.3361 10.4764 21.1762C12.9261 23.9466 15.3473 23.6979 17.0326 23.6979Z"
      fill="white"
    />
  </svg>
);
