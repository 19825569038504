import { getColor, IIconProps } from './utils';

export const TelegramIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="32" height="32" rx="16" fill={getColor(color)} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0899 25.3552L22.0906 25.3532L22.1093 25.3033L25.3333 7.95835V7.90201C25.3333 7.46985 25.1837 7.09257 24.8581 6.86627C24.573 6.66819 24.2454 6.65408 24.0156 6.67269C23.7726 6.69237 23.5435 6.75709 23.3849 6.81096C23.3033 6.83868 23.235 6.86539 23.1862 6.88559C23.1616 6.89572 23.1418 6.90433 23.1273 6.91073L23.1131 6.91711L5.1375 14.4417L5.13256 14.4436C5.12285 14.4473 5.11001 14.4525 5.0945 14.4589C5.06359 14.4717 5.02151 14.4901 4.97207 14.514C4.87501 14.561 4.74091 14.6334 4.6049 14.7328C4.37391 14.9016 3.93407 15.3012 4.00834 15.9347C4.06986 16.4595 4.40885 16.7927 4.63738 16.9653C4.75989 17.0578 4.87709 17.1245 4.96291 17.1681C5.0064 17.1902 5.04334 17.2071 5.07085 17.219C5.08464 17.225 5.09617 17.2298 5.10509 17.2334L5.11652 17.238L5.12382 17.2409L8.26877 18.3707C8.25816 18.5812 8.27773 18.7959 8.33005 19.0077L9.90539 25.3845C10.0922 26.1406 10.7332 26.6672 11.4659 26.6667C12.1228 26.6662 12.7053 26.2422 12.9528 25.6095L15.4123 22.8033L19.6363 26.2591L19.6964 26.287C20.0802 26.4657 20.4387 26.5223 20.7666 26.4746C21.0939 26.4268 21.3541 26.2804 21.5495 26.1135C21.7418 25.9494 21.8736 25.7641 21.9565 25.6246C21.9986 25.5537 22.03 25.4914 22.0517 25.4445C22.0627 25.421 22.0713 25.4011 22.0777 25.3856L22.0858 25.3656L22.0887 25.3582L22.0899 25.3552ZM9.88939 18.5691C9.85359 18.4241 9.9098 18.2711 10.0284 18.1908L20.6956 10.9622C20.6956 10.9622 21.323 10.5558 21.3006 10.9622C21.3006 10.9622 21.4126 11.034 21.0764 11.3687C20.7575 11.6866 13.4735 19.1906 12.7365 19.9499C12.694 19.9938 12.6686 20.0441 12.6536 20.1051L11.4647 24.9458L9.88939 18.5691Z"
      fill="white"
    />
  </svg>
);
