import { getColor, IIconProps } from './utils';

export const MoonIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M28.8535 17.3333C28.661 17.1743 28.4278 17.0724 28.1803 17.0394C27.9327 17.0064 27.6809 17.0436 27.4535 17.1467C26.0436 17.7919 24.5107 18.1239 22.9602 18.12C20.092 18.1165 17.3415 16.9793 15.3084 14.9561C13.2753 12.933 12.1244 10.1882 12.1068 7.32001C12.1129 6.42112 12.2248 5.52607 12.4402 4.65334C12.4859 4.42073 12.4687 4.18013 12.3905 3.95635C12.3123 3.73256 12.1758 3.53368 11.9952 3.38018C11.8145 3.22669 11.5962 3.12414 11.3627 3.08309C11.1292 3.04205 10.889 3.064 10.6668 3.14668C8.57661 4.08655 6.75961 5.54236 5.38653 7.37732C4.01344 9.21228 3.12935 11.3662 2.81731 13.6367C2.50527 15.9071 2.77552 18.2197 3.60267 20.357C4.42983 22.4944 5.78673 24.3864 7.54591 25.8553C9.3051 27.3242 11.4088 28.3218 13.6594 28.7544C15.9101 29.187 18.2337 29.0404 20.4121 28.3283C22.5905 27.6162 24.5521 26.3621 26.1127 24.6837C27.6733 23.0053 28.7816 20.9577 29.3335 18.7333C29.4007 18.4787 29.391 18.2098 29.3056 17.9606C29.2202 17.7115 29.0629 17.4932 28.8535 17.3333ZM16.1868 26.2533C13.9492 26.2376 11.7711 25.5305 9.95084 24.229C8.13056 22.9275 6.75707 21.0951 6.01842 18.9828C5.27978 16.8706 5.2121 14.5816 5.82463 12.4293C6.43717 10.2771 7.69999 8.36681 9.44018 6.96001V7.32001C9.44371 10.9047 10.8693 14.3415 13.404 16.8762C15.9387 19.4109 19.3755 20.8365 22.9602 20.84C23.9014 20.8435 24.8402 20.7451 25.7602 20.5467C24.8401 22.2874 23.4621 23.7438 21.7751 24.7589C20.088 25.7739 18.1558 26.3091 16.1868 26.3067V26.2533Z" />
  </svg>
);
