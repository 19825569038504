import { getColor, IIconProps } from './utils';

export const RainbowIcon = ({ color, size = '32', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.66683 16C6.31321 16 5.97407 16.1405 5.72402 16.3905C5.47397 16.6406 5.3335 16.9797 5.3335 17.3333C5.3335 17.687 5.47397 18.0261 5.72402 18.2762C5.97407 18.5262 6.31321 18.6667 6.66683 18.6667C8.43494 18.6667 10.1306 19.3691 11.3809 20.6193C12.6311 21.8695 13.3335 23.5652 13.3335 25.3333C13.3335 25.687 13.474 26.0261 13.724 26.2762C13.9741 26.5262 14.3132 26.6667 14.6668 26.6667C15.0205 26.6667 15.3596 26.5262 15.6096 26.2762C15.8597 26.0261 16.0002 25.687 16.0002 25.3333C16.0002 22.858 15.0168 20.484 13.2665 18.7337C11.5162 16.9833 9.14218 16 6.66683 16ZM6.66683 5.33334C6.31321 5.33334 5.97407 5.47382 5.72402 5.72387C5.47397 5.97392 5.3335 6.31305 5.3335 6.66668C5.3335 7.0203 5.47397 7.35944 5.72402 7.60949C5.97407 7.85953 6.31321 8.00001 6.66683 8.00001C11.2639 8.00001 15.6727 9.82619 18.9233 13.0768C22.174 16.3275 24.0002 20.7363 24.0002 25.3333C24.0002 25.687 24.1406 26.0261 24.3907 26.2762C24.6407 26.5262 24.9799 26.6667 25.3335 26.6667C25.6871 26.6667 26.0263 26.5262 26.2763 26.2762C26.5264 26.0261 26.6668 25.687 26.6668 25.3333C26.6668 20.029 24.5597 14.9419 20.809 11.1912C17.0582 7.44048 11.9712 5.33334 6.66683 5.33334ZM6.66683 10.6667C6.31321 10.6667 5.97407 10.8072 5.72402 11.0572C5.47397 11.3072 5.3335 11.6464 5.3335 12C5.3335 12.3536 5.47397 12.6928 5.72402 12.9428C5.97407 13.1929 6.31321 13.3333 6.66683 13.3333C9.84943 13.3333 12.9017 14.5976 15.1521 16.8481C17.4025 19.0985 18.6668 22.1507 18.6668 25.3333C18.6668 25.687 18.8073 26.0261 19.0574 26.2762C19.3074 26.5262 19.6465 26.6667 20.0002 26.6667C20.3538 26.6667 20.6929 26.5262 20.943 26.2762C21.193 26.0261 21.3335 25.687 21.3335 25.3333C21.3335 21.4435 19.7883 17.713 17.0377 14.9624C14.2872 12.2119 10.5567 10.6667 6.66683 10.6667Z" />
  </svg>
);
