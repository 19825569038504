import { getColor, IIconProps } from './utils';

export const LoaderCircleIcon = ({
  color,
  size = '24',
  ...props
}: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M12 22C13.1046 22 14 21.1046 14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1046 10.8954 22 12 22Z"
    />
    <path d="M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z" />
    <path
      opacity="0.5"
      d="M6.34277 19.657C7.44734 19.657 8.34277 18.7616 8.34277 17.657C8.34277 16.5524 7.44734 15.657 6.34277 15.657C5.2382 15.657 4.34277 16.5524 4.34277 17.657C4.34277 18.7616 5.2382 19.657 6.34277 19.657Z"
    />
    <path d="M17.6572 8.34299C18.7618 8.34299 19.6572 7.44756 19.6572 6.34299C19.6572 5.23842 18.7618 4.34299 17.6572 4.34299C16.5527 4.34299 15.6572 5.23842 15.6572 6.34299C15.6572 7.44756 16.5527 8.34299 17.6572 8.34299Z" />
    <path
      opacity="0.5"
      d="M4.00002 14.001C5.10514 14.001 6.00102 13.1051 6.00102 12C6.00102 10.8949 5.10514 9.99899 4.00002 9.99899C2.8949 9.99899 1.99902 10.8949 1.99902 12C1.99902 13.1051 2.8949 14.001 4.00002 14.001Z"
    />
    <path d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z" />
    <path
      opacity="0.5"
      d="M6.34277 8.34399C7.44734 8.34399 8.34277 7.44856 8.34277 6.34399C8.34277 5.23942 7.44734 4.34399 6.34277 4.34399C5.2382 4.34399 4.34277 5.23942 4.34277 6.34399C4.34277 7.44856 5.2382 8.34399 6.34277 8.34399Z"
    />
    <path d="M17.6572 19.658C18.7618 19.658 19.6572 18.7626 19.6572 17.658C19.6572 16.5534 18.7618 15.658 17.6572 15.658C16.5527 15.658 15.6572 16.5534 15.6572 17.658C15.6572 18.7626 16.5527 19.658 17.6572 19.658Z" />
  </svg>
);
