import { getColor, IIconProps } from './utils';

export const ViewsIcon = ({ color, size = '24', ...props }: IIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={getColor(color)}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 10.04C11.4937 10.04 11.0081 10.2465 10.6501 10.6141C10.292 10.9816 10.0909 11.4802 10.0909 12C10.0909 12.5198 10.292 13.0184 10.6501 13.3859C11.0081 13.7535 11.4937 13.96 12 13.96C12.5063 13.96 12.9919 13.7535 13.3499 13.3859C13.708 13.0184 13.9091 12.5198 13.9091 12C13.9091 11.4802 13.708 10.9816 13.3499 10.6141C12.9919 10.2465 12.5063 10.04 12 10.04ZM12 15.2667C11.1561 15.2667 10.3468 14.9225 9.75011 14.3099C9.15341 13.6973 8.81818 12.8664 8.81818 12C8.81818 11.1336 9.15341 10.3027 9.75011 9.69012C10.3468 9.07751 11.1561 8.73334 12 8.73334C12.8439 8.73334 13.6532 9.07751 14.2499 9.69012C14.8466 10.3027 15.1818 11.1336 15.1818 12C15.1818 12.8664 14.8466 13.6973 14.2499 14.3099C13.6532 14.9225 12.8439 15.2667 12 15.2667ZM12 7.10001C8.81818 7.10001 6.10091 9.13187 5 12C6.10091 14.8681 8.81818 16.9 12 16.9C15.1818 16.9 17.8991 14.8681 19 12C17.8991 9.13187 15.1818 7.10001 12 7.10001Z" />
  </svg>
);
